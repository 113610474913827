<template>
  <div style="background: #F5F5F5;    display: flex;flex-direction: column;justify-content: center;align-items: center;">
    <h2> 请求跳转中...</h2>
  </div>
</template>
<script>

export default {
  components: {
  },
  directives: {
  },
  data () {
    return {

    }
  },
  created () {
    var url = "";
    var ua = window.navigator.userAgent.toLowerCase();
    debugger
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      url = this.generateWXURL('wxcaf699283f44bd0f', window.location.origin + '/paycenter/wxpay/' + this.$store.state.qrCode, "snsapi_base", "")
    } else if (ua.match(/Alipay/i) == "alipay") {
      url = this.generateAliURL('2021002176638367', window.location.origin + '/paycenter/alipay/' + this.$store.state.qrCode, "auth_base", "")
    }
    if (url == "") {
      this.$router.push({
        name: 'stop'
      })
    } else {
      window.location.href = url;
    }
  },
  methods: {
    generateWXURL (appID, redirect_uri, scope, state) {
      return "https://open.weixin.qq.com/connect/oauth2/authorize?" +
        "appid=" + appID +
        "&redirect_uri=" + encodeURIComponent(redirect_uri) +
        "&response_type=code" +
        "&scope=" + scope +
        "&state=" + state +
        "&connect_redirect=1#wechat_redirect";
    },
    generateAliURL (appID, redirect_uri, scope, state) {
      return "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?" +
        "app_id=" + appID +
        "&scope=" + scope +
        "&redirect_uri=" + encodeURIComponent(redirect_uri) +
        "&state=" + state;
    }
  }
}
</script>